import './App.scss';
import catsu_coin from './assets/catsu_coin.png'
import catsu_rocket from './assets/catsu_rocketship.jpeg'
import { Component } from 'react'; // Importing Component from 'react' library

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="container">
                    <header id="header" className="header">
                        <nav>
                            <div className="logo">
                                <img className="catsu-coin-logo" src={catsu_coin}/>
                                <span>CATSU TOKEN</span>
                            </div>
                        </nav>
                    </header>
                    <div className="hero">
                        <div className="hero-title">CATSU TOKEN</div>
                    </div>

                    <div className="what-is">
                        <section className="text-section">
                            <h1>What is Catsu Token?</h1>
                            <p className="subheader">
                                An peer-to-peer digital currency, loved by cat enthusiasts
                                around the world.
                            </p>
                            <p className="description">
                                At its core, Catsu Token is the purrfect crypto movement that brings joy
                                and whimsy to the world! It’s an open-source, peer-to-peer
                                cryptocurrency that uses blockchain technology—a secure,
                                decentralized system where information is stored as a public ledger
                                and maintained by a network of computers known as nodes. But what
                                truly makes Catsu Token stand out is its spirit, the lively
                                community of cat lovers and crypto enthusiasts who share the same
                                passion! Join the pride and learn more.
                            </p>
                        </section>

                        <section className="image-section">
                            <img src={catsu_rocket} alt="Catsu Rocketship" />
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
